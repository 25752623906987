import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { useNavigationWithState } from './useNavigationWithState';

export const useQuerySearchParams = () => {
  const history = useHistory();
  const { push } = useNavigationWithState();

  const setParams = useCallback(
    (params: Record<string, string>) => {
      const urlParams = new URLSearchParams(history.location.search);
      Object.keys(params).forEach((key) => urlParams.set(key, params[key]));

      push(`${history.location.pathname}?${urlParams}`);
    },
    [history.location.pathname, history.location.search, push]
  );

  return { setParams };
};
