import React, { FC, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { ChatMessageSenderEnum } from '@zarn/vendor/dist/search';

import { selectUser } from 'containers/User/user.slice';
import { Avatar } from './Avatar';
import { PaperProps } from '@mui/material/Paper/Paper';
import {
  BoxWithoutScroll,
  PaperWithoutScroll,
} from 'common/components/WithoutScroll/WithoutScroll';

interface MessageProps extends PaperProps {
  children: ReactNode;
  sender: ChatMessageSenderEnum | undefined;
  initials?: string;
  isContent: boolean;
  extra?: ReactNode;
  button?: ReactNode;
}

export const Message: FC<MessageProps> = ({
  children,
  sender,
  initials,
  isContent,
  extra,
  button,
  ...props
}) => {
  const user = useSelector(selectUser);
  const isUser = sender === ChatMessageSenderEnum.User;

  if (!user) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isUser ? 'row-reverse' : 'row',
      }}
      p={1}
    >
      {(isContent || button) && <Avatar isUser={isUser} initials={initials} />}
      <BoxWithoutScroll
        sx={{
          overflow: 'scroll',
          padding: '0.25rem',
        }}
      >
        {isContent && (
          <PaperWithoutScroll
            sx={
              isUser
                ? {
                    marginRight: '0.25rem',
                    overflowX: 'auto',
                    padding: '0.25rem',
                    ...props.sx,
                  }
                : {
                    marginLeft: '0.25rem',
                    overflowX: 'auto',
                    padding: '0.25rem',
                    ...props.sx,
                  }
            }
          >
            <Typography variant="body2" component="div" p={1}>
              {children}
            </Typography>

            {extra}
          </PaperWithoutScroll>
        )}
        {button}
      </BoxWithoutScroll>
    </Box>
  );
};
