import { useCallback, useState } from 'react';
import { BotTypeMessage } from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { deserializeAxiosError } from 'common/utils/error';
import { buildChatConversation } from './helpers';
import { getTenantBotType } from 'api/tenantSettingsApi/tenantSettingsApi.utils';
import { requestChatAnswer } from 'api/chatApi/chatApi';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { GenericError } from '@zarn/vendor/dist/saved-results';
import { Conversation } from '../Chat.types';
import { Nullable } from 'common/utils/assert';
import { NoteDetails } from 'api/notesApi/notesApi.types';
import { ChatContext } from 'api/chatApi/chatApi.types';
import { captureException } from '@sentry/react';
import { useAssertTenantSettings } from 'common/hooks/useAssertTenantSettings';

interface Props<T extends object = {}> {
  context: ChatContext;
  conversation: Nullable<Conversation<T>>;
  botParams?: Record<string, any>;
  onConversationChange: (
    conversation: Conversation<T>
  ) => Promise<NoteDetails | { content: string } | null>;
}

export const useSendChatMessage = <T extends object = {}>({
  context,
  conversation,
  botParams,
  onConversationChange,
}: Props<T>) => {
  const { tenant } = useParsedHostname();
  const { tenantSettings } = useAssertTenantSettings();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<GenericError | null>(null);

  const send = useCallback(
    async (message: BotTypeMessage) => {
      const updatedConversations = buildChatConversation(message, conversation);

      await onConversationChange(updatedConversations);

      const tenantBotType = getTenantBotType(
        updatedConversations.botType,
        tenantSettings.chat
      );

      const response = await requestChatAnswer(
        {
          chatResponseForm: {
            context,
            conversation: updatedConversations.messages,
            botType: tenantBotType,
            botParams: botParams,
          },
        },
        tenant
      );

      const responseConversation: Conversation<T> = {
        ...updatedConversations,
        messages: response.data.conversation,
      };

      await onConversationChange(responseConversation);

      return responseConversation;
    },
    [
      tenantSettings,
      context,
      conversation,
      onConversationChange,
      tenant,
      botParams,
    ]
  );

  const sendMessage = useCallback(
    async (botMessage: BotTypeMessage) => {
      try {
        setIsLoading(true);
        setError(null);

        return await send(botMessage);
      } catch (err) {
        captureException(err);
        setError(deserializeAxiosError(err));
      } finally {
        setIsLoading(false);
      }
      return null;
    },
    [send]
  );

  return { isLoading, error, sendMessage };
};
