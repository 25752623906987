import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { Nullable } from '../../../utils/assert';
import { NoteItemMarkdown } from './NoteItemMarkdown/NoteItemMarkdown';
import { Conversation } from 'containers/Chat/Chat.types';
import { ShortConversationList } from './ShortConversationList/ShortConversationList';
import { ConversationList } from '../../../../containers/Chat/Conversation/ConversationList';
import { BoxProps } from '@mui/material/Box/Box';

export interface NoteItemContentProps extends BoxProps {
  editable?: boolean;
  highlight?: string;
  content: string;
  isChatConversation: Nullable<boolean>;
  isShortConversation?: boolean;
  initials: string | undefined;
  conversation: Nullable<Conversation>;
}

export const NoteItemContent = ({
  editable,
  highlight,
  content,
  isChatConversation,
  isShortConversation,
  initials,
  conversation,
  ...props
}: NoteItemContentProps) => {
  const contentComponent = useMemo(() => {
    if (!conversation) {
      return (
        <NoteItemMarkdown content={highlight || content} editable={editable} />
      );
    }
    if (isShortConversation) {
      return <ShortConversationList conversation={conversation} />;
    }
    return <ConversationList conversation={conversation} initials={initials} />;
  }, [
    content,
    conversation,
    editable,
    highlight,
    initials,
    isShortConversation,
  ]);

  return (
    <Box
      sx={{
        maxHeight: isChatConversation ? (isShortConversation ? 40 : 400) : 200,
        overflowY: isShortConversation ? 'initial' : 'auto',
      }}
      data-testid="NoteItemContent"
    >
      <Box {...props}>{contentComponent}</Box>
    </Box>
  );
};
