import React, { FC } from 'react';
import { Conversation } from '../../../../../containers/Chat/Chat.types';
import { Box, Typography } from '@mui/material';
import { Avatar } from '../../../../../containers/Chat/Message/Avatar';
import NoteAnnotationHighlightItem from '../../NoteAnnotationHighlightItem';

interface Props {
  conversation: Conversation;
}

export const ShortConversationList: FC<Props> = ({ conversation }) => {
  const message = conversation.messages[0];

  return (
    <Box data-testid="ShortConversationList">
      <Box sx={{ display: 'flex' }}>
        <Avatar isUser={false} />
        <Typography
          variant="body2"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            mr: 1,
            ml: 1,
            textOverflow: 'ellipsis',
            flex: 1,
            maxHeight: '48px',
          }}
        >
          {message.image && (
            <NoteAnnotationHighlightItem
              contentType={'image'}
              contentBody={message.image}
            />
          )}
          {message.content}
        </Typography>
      </Box>
    </Box>
  );
};
