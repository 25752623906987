import { ChatApi, Configuration } from '@zarn/vendor/dist/search';
import { BASE_HEADERS, SERVICE_URL } from '../apiConfig';
import AxiosInstance from '../axiosInstance';
import { AxiosResponse } from 'axios';
import { ChatPayload, ChatResponse } from './chatApi.types';
import { deserializeChatResponse, serializeChatPayload } from './chatApi.utils';

export const chatApi = new ChatApi(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  AxiosInstance
);

export const requestChatAnswer = async (
  payload: ChatPayload,
  tenant: string,
  indexCluster?: string
): Promise<AxiosResponse<ChatResponse>> => {
  const chatRequestPayload = serializeChatPayload(payload);
  const { data, ...response } = await chatApi.respond({
    chatResponseForm: chatRequestPayload,
    requesterUuid: '',
    userRoles: '',
    tenant,
    indexCluster,
  });

  return { ...response, data: deserializeChatResponse(data) };
};
