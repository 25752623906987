import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { getDocIdWithoutChunk } from 'common/utils/documents';

interface useEvidenceTitleProps {
  docId: string;
}

export const useCompareEvidence = ({ docId }: useEvidenceTitleProps) => {
  const [isSameDocument, setIsSameDocument] = useState(false);

  const match = useRouteMatch<{ docId: string }>();

  useEffect(() => {
    setIsSameDocument(
      getDocIdWithoutChunk(match?.params?.docId) === getDocIdWithoutChunk(docId)
    );
  }, [match?.params?.docId, docId]);

  return {
    isSameDocument,
  };
};
