import React, { FC, useMemo } from 'react';
import { useEvidenceHit } from '../../hooks/useEvidenceHit';
import { useCompareEvidence } from '../../hooks/useCompareEvidence';
import { Parser } from '../../hooks/useConversation/parsers/Parser';
import { defined, isExist } from 'common/utils/assert';

import { ChunkHighlight } from 'common/interfaces/ChunkHighlight.interfaces';
import { EvidenceContent } from './EvidenceContent';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface EvidenceItemProps {
  evidence: string;
  docId: string;
  documentHitUrl?: string;
  label: string;
  onChunkClick?: (chunks: ChunkHighlight[]) => void;
  onEvidenceItemClick?: () => void;
}

export const EvidenceItem: FC<EvidenceItemProps> = ({
  evidence,
  docId,
  documentHitUrl,
  label,
  onChunkClick,
  onEvidenceItemClick,
}) => {
  const { t } = useTranslation('common');
  const { isSameDocument } = useCompareEvidence({ docId });
  const { isLoading, title, chunkHighlights } = useEvidenceHit({
    documentHitUrl,
    options: { enabled: !isSameDocument },
  });

  const handleClick = () =>
    onEvidenceItemClick
      ? onEvidenceItemClick()
      : onChunkClick?.(defined(chunkHighlights, 'EvidenceHit.chunkHighlights'));

  const preparedLabel = useMemo(() => Parser.prepareLabel(label), [label]);

  const disabled = isExist(onEvidenceItemClick)
    ? false
    : !chunkHighlights?.length;

  const tooltip = disabled
    ? t('chat.evidence.notClickable')
    : t('chat.evidence.clickable');

  return (
    <Tooltip
      title={tooltip}
      placement="left"
      data-testid="EvidenceItem-Tooltip"
    >
      <div>
        <EvidenceContent
          title={title}
          label={preparedLabel}
          evidence={evidence}
          disabled={disabled}
          isLoading={isLoading}
          isSameDocument={isSameDocument}
          onClick={handleClick}
        />
      </div>
    </Tooltip>
  );
};
