import {
  ChatContextFromContent,
  ChatContextFromDocIds,
  ChatContexts,
  ChatMessage,
  ChatMessageEvidence,
  ChatMessageSenderEnum,
  ChatResponseForm,
  ChatResponseItem,
  FunctionCallRequest,
} from '@zarn/vendor/dist/search';
import {
  ChatContext,
  ChatMessageElement,
  ChatPayload,
  ChatResponse,
  ContentContext,
  DocIdContext,
  Evidence,
  FunctionCallProperties,
} from './chatApi.types';
import { RetrievalUnitEnum } from 'common/enums';
import { serializeKey } from 'common/utils/serialize.helpers';

const serializeFromDocIds = (
  fromDocIds: DocIdContext
): ChatContextFromDocIds => ({
  document_ids: fromDocIds.docId,
  retrieval_unit: fromDocIds.retrievalUnit,
});

const serializeFromDocumentContent = (
  fromDocContent: ContentContext
): ChatContextFromContent => ({
  document_id: fromDocContent.docId,
  content: fromDocContent.docContent,
});

const serializeChatContext = (context: ChatContext): ChatContexts => ({
  from_document_ids: context.fromDocIds
    ? serializeFromDocIds(context.fromDocIds)
    : undefined,
  from_document_content: context.fromDocContent
    ? context.fromDocContent.map(serializeFromDocumentContent)
    : undefined,
});

const serializeEvidenceItem = (
  evidenceItem: Evidence
): ChatMessageEvidence => ({
  document_id: evidenceItem.documentId,
  text_extract: evidenceItem.textExtract,
  ...serializeKey(evidenceItem, 'anchorText', 'anchor_text'),
  ...serializeKey(evidenceItem, 'explanation'),
  ...serializeKey(evidenceItem, 'uri'),
  ...serializeKey(evidenceItem, 'documentHitUrl', 'document_hit_url'),
});

const serializeBotParams = (
  botParams: Record<string, any>
): Record<string, any> => ({
  ...serializeKey(botParams, 'tagName', 'tag_name'),
  ...serializeKey(botParams, 'tagDescription', 'tag_description'),
  ...serializeKey(botParams, 'tagSize', 'tag_size'),
  ...serializeKey(botParams, 'tagDate', 'tag_date'),
});

const serializeChatResponseForm = (
  conversationItem: ChatMessageElement
): ChatMessage => {
  const chatMessageElement: ChatMessage = {
    sender: conversationItem.sender,
    content: conversationItem.content,
    ...(conversationItem.image ? { image_uri: conversationItem.image } : {}),
  };

  if (conversationItem.evidences) {
    chatMessageElement.evidences = conversationItem.evidences.map(
      serializeEvidenceItem
    );
  }

  if (conversationItem.functionCallRequest) {
    chatMessageElement.function_call_request =
      conversationItem.functionCallRequest;
  }

  return chatMessageElement;
};

export const serializeChatPayload = ({
  chatResponseForm,
}: ChatPayload): ChatResponseForm => ({
  context: serializeChatContext(chatResponseForm.context),
  conversation: chatResponseForm.conversation.map(serializeChatResponseForm),
  bot_type: chatResponseForm.botType,
  ...(chatResponseForm.botParams
    ? { bot_params: serializeBotParams(chatResponseForm.botParams) }
    : {}),
});

const deserializeRetrievalUnit = (retrievalUnit: string): RetrievalUnitEnum => {
  switch (retrievalUnit) {
    case 'document':
      return RetrievalUnitEnum.Document;
    case 'sentence':
      return RetrievalUnitEnum.Sentence;
    case 'chunk':
      return RetrievalUnitEnum.Chunk;
    default:
      return RetrievalUnitEnum.Document;
  }
};

const deserializeFromDocIds = (
  fromDocIds: ChatContextFromDocIds
): DocIdContext => ({
  docId: fromDocIds.document_ids,
  retrievalUnit: deserializeRetrievalUnit(fromDocIds.retrieval_unit),
  contextFields: [],
});

const deserializeFromDocumentContent = (
  fromDocContent: ChatContextFromContent
): ContentContext => ({
  docId: fromDocContent.document_id,
  docContent: fromDocContent.content,
});

const deserializeChatContext = (context: ChatContexts): ChatContext => ({
  fromDocIds: context.from_document_ids
    ? deserializeFromDocIds(context.from_document_ids)
    : undefined,
  fromDocContent: context.from_document_content
    ? context.from_document_content.map(deserializeFromDocumentContent)
    : undefined,
});

const deserializeSender = (sender: string): ChatMessageSenderEnum => {
  switch (sender) {
    case 'bot':
      return ChatMessageSenderEnum.Bot;
    case 'user':
    default:
      return ChatMessageSenderEnum.User;
  }
};

const buildEvidenceLabel = (index: number): string => `[^${index + 1}]`;

const deserializeEvidenceItem = (
  evidenceItem: ChatMessageEvidence,
  index: number
): Evidence => ({
  documentId: evidenceItem.document_id,
  textExtract: evidenceItem.text_extract,
  ...serializeKey(evidenceItem, 'anchor_text', 'anchorText'),
  ...serializeKey(evidenceItem, 'explanation'),
  ...serializeKey(evidenceItem, 'uri'),
  ...serializeKey(evidenceItem, 'document_hit_url', 'documentHitUrl'),
  label: buildEvidenceLabel(index),
});

const deserializeEvidences = (evidences: ChatMessageEvidence[]): Evidence[] =>
  evidences.map(deserializeEvidenceItem);

export const deserializeContent = (
  content: string,
  evidences: Evidence[]
): string =>
  evidences.reduce(
    (acc, evidence) =>
      evidence.anchorText
        ? acc.replaceAll(evidence.anchorText, evidence.label)
        : acc,
    content
  );

const deserializeFunctionCallProps = (
  props: FunctionCallRequest
): FunctionCallProperties => ({
  name: props.name,
  ...(props.params ? { params: props.params } : {}),
});

const deserializeConversation = (message: ChatMessage): ChatMessageElement => {
  const sender = deserializeSender(message.sender);
  const evidences = message.evidences
    ? deserializeEvidences(message.evidences)
    : undefined;

  const result: ChatMessageElement = {
    sender,
    content: message.content,
    ...(message.function_call_request
      ? {
          functionCallRequest: deserializeFunctionCallProps(
            message.function_call_request
          ),
        }
      : {}),
    ...(message.image_uri ? { image: message.image_uri } : {}),
  };

  if (evidences) {
    result.evidences = evidences;
  }

  return result;
};

export const deserializeChatResponse = (
  response: ChatResponseItem
): ChatResponse => ({
  context: deserializeChatContext(response.context),
  conversation: response.conversation.map(deserializeConversation),
  botType: response.bot_type,
});
